import React from 'react';
import { useLocation } from 'react-router-dom';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { FaCogs } from 'react-icons/fa';
import { FaWindowClose } from 'react-icons/fa';
import GenericDropdown from './SelectDropdown';
import { concepts, periodicities, currencies, types } from '../utils';
import moment from 'moment';

const FilterForm = ({
	filterOptions,
	handleInputChange,
	clearFilter,
	handleApplyFilter,
	isOpen,
	handleDropdownToggle,
	accountsList,
}) => {
	const handleSubmit = (e) => {
		e.preventDefault();
		handleApplyFilter(filterOptions.formType);
	};
	const debtConcepts = concepts;
	const creditConcepts = ['prestamos-amortizable', 'prestamos-interés'];
	const accountTypes = ['acreedores', 'deudores'];
	const paidOptions = ['Si', 'No'];
	const { pathname } = useLocation();

	return (
		<div
			className={
				pathname.includes('/reports') || pathname.includes('/predictions') ? '' : 'sidebar-dropdown'
			}
		>
			{!pathname.includes('/reports') && !pathname.includes('/predictions') ? (
				<Row>
					<Col className='d-flex justify-content-end'>
						<Button className='btn  m-2' onClick={handleDropdownToggle} aria-expanded={isOpen}>
							<FaWindowClose />
						</Button>
					</Col>
				</Row>
			) : (
				''
			)}
			<Form onSubmit={handleSubmit}>
				{filterOptions.formType === 'Deudas' && (
					<>
						{!pathname.includes('/reports') && !pathname.includes('/predictions') ? (
							<h4 className='text-center p-2'>
								<FaCogs></FaCogs> Filtro avanzado ({filterOptions.formType})
							</h4>
						) : (
							''
						)}
						<Form.Group as={Row} className='mb-3 align-items-center'>
							<Form.Label column md={3}>
								Concepto:
							</Form.Label>
							<Col md={9}>
								<GenericDropdown
									label='Concepto'
									items={debtConcepts}
									selected={filterOptions.concept}
									onChange={handleInputChange}
									name='concept'
									isInFilterForm={true}
								/>
							</Col>
						</Form.Group>
						<Form.Group as={Row} className='mb-3 align-items-center'>
							<Form.Label column md={3} size='sm'>
								Moneda:
							</Form.Label>
							<Col md={9}>
								<GenericDropdown
									label='Moneda'
									items={currencies}
									selected={filterOptions.currency}
									onChange={handleInputChange}
									name='currency'
									isInFilterForm={true}
								/>
							</Col>
						</Form.Group>
						<Form.Group as={Row} className='mb-3 align-items-center'>
							<Form.Label column md={2} size='sm'>
								Fecha:
							</Form.Label>
							<Col md={5}>
								<Form.Control
									type='date'
									name='dateFrom'
									size='sm'
									value={filterOptions.dateFrom}
									onChange={handleInputChange}
								/>
							</Col>
							<Col md={5}>
								<Form.Control
									type='date'
									name='dateTo'
									size='sm'
									value={filterOptions.dateTo}
									onChange={handleInputChange}
								/>
							</Col>
						</Form.Group>
						<Form.Group as={Row} className='mb-3 align-items-center'>
							<Form.Label column md={2} size='sm'>
								Vto:
							</Form.Label>
							<Col md={5}>
								<Form.Control
									type='date'
									name='endDateFrom'
									size='sm'
									value={filterOptions.endDateFrom}
									onChange={handleInputChange}
								/>
							</Col>
							<Col md={5}>
								<Form.Control
									type='date'
									name='endDateTo'
									size='sm'
									value={filterOptions.endDateTo}
									onChange={handleInputChange}
								/>
							</Col>
						</Form.Group>
						<Form.Group as={Row} className='mb-3 align-items-center'>
							<Form.Label column md={2} size='sm'>
								Monto:
							</Form.Label>
							<Col md={5}>
								<Form.Control
									type='number'
									name='amountFrom'
									size='sm'
									value={filterOptions.amountFrom}
									onChange={handleInputChange}
									placeholder='Desde'
								/>
							</Col>
							<Col md={5}>
								<Form.Control
									type='number'
									name='amountTo'
									size='sm'
									value={filterOptions.amountTo}
									onChange={handleInputChange}
									placeholder='Hasta'
								/>
							</Col>
						</Form.Group>
						<Form.Group as={Row} className='mb-3 align-items-center'>
							<Form.Label column md={2} size='sm'>
								Capital:
							</Form.Label>
							<Col md={5}>
								<Form.Control
									type='number'
									name='capitalFrom'
									size='sm'
									value={filterOptions.capitalFrom}
									onChange={handleInputChange}
									placeholder='Desde'
								/>
							</Col>
							<Col md={5}>
								<Form.Control
									type='number'
									name='capitalTo'
									size='sm'
									value={filterOptions.capitalTo}
									onChange={handleInputChange}
									placeholder='Hasta'
								/>
							</Col>
						</Form.Group>
					</>
				)}
				{filterOptions.formType === 'Pasividades' && (
					<>
						{!pathname.includes('/reports') && !pathname.includes('/predictions') ? (
							<h4 className='text-center p-2'>
								<FaCogs></FaCogs> Filtro avanzado ({filterOptions.formType})
							</h4>
						) : (
							''
						)}
						<Form.Group as={Row} className='mb-3 align-items-center'>
							<Form.Label column md={3}>
								Concepto:
							</Form.Label>
							<Col md={9}>
								<GenericDropdown
									label='Concepto'
									items={creditConcepts}
									selected={filterOptions.concept}
									onChange={handleInputChange}
									name='concept'
									isInFilterForm={true}
								/>
							</Col>
						</Form.Group>
						<Form.Group as={Row} className='mb-3 align-items-center'>
							<Form.Label column md={3}>
								Periodicidad:
							</Form.Label>
							<Col md={9}>
								<GenericDropdown
									label='Periodicidad'
									items={periodicities}
									selected={filterOptions.periodicity}
									onChange={handleInputChange}
									name='periodicity'
									isInFilterForm={true}
								/>
							</Col>
						</Form.Group>
						<Form.Group as={Row} className='mb-3 align-items-center'>
							<Form.Label column md={3} size='sm'>
								Moneda:
							</Form.Label>
							<Col md={9}>
								<GenericDropdown
									label='Moneda'
									items={currencies}
									selected={filterOptions.currency}
									onChange={handleInputChange}
									name='currency'
									isInFilterForm={true}
								/>
							</Col>
						</Form.Group>
						<Form.Group as={Row} className='mb-3 align-items-center'>
							<Form.Label column md={2} size='sm'>
								Fecha:
							</Form.Label>
							<Col md={5}>
								<Form.Control
									type='date'
									name='dateFrom'
									size='sm'
									value={filterOptions.dateFrom}
									onChange={handleInputChange}
								/>
							</Col>
							<Col md={5}>
								<Form.Control
									type='date'
									name='dateTo'
									size='sm'
									value={filterOptions.dateTo}
									onChange={handleInputChange}
								/>
							</Col>
						</Form.Group>
						<Form.Group as={Row} className='mb-3 align-items-center'>
							<Form.Label column md={2} size='sm'>
								Vto:
							</Form.Label>
							<Col md={5}>
								<Form.Control
									type='date'
									name='endDateFrom'
									size='sm'
									value={filterOptions.endDateFrom}
									onChange={handleInputChange}
								/>
							</Col>
							<Col md={5}>
								<Form.Control
									type='date'
									name='endDateTo'
									size='sm'
									value={filterOptions.endDateTo}
									onChange={handleInputChange}
								/>
							</Col>
						</Form.Group>
						<Form.Group as={Row} className='mb-3 align-items-center'>
							<Form.Label column md={2} size='sm'>
								Monto:
							</Form.Label>
							<Col md={5}>
								<Form.Control
									type='number'
									name='amountFrom'
									size='sm'
									value={filterOptions.amountFrom}
									onChange={handleInputChange}
									placeholder='Desde'
								/>
							</Col>
							<Col md={5}>
								<Form.Control
									type='number'
									name='amountTo'
									size='sm'
									value={filterOptions.amountTo}
									onChange={handleInputChange}
									placeholder='Hasta'
								/>
							</Col>
						</Form.Group>
					</>
				)}
				{filterOptions.formType === 'Alquileres' && (
					<>
						{!pathname.includes('/reports') && !pathname.includes('/predictions') ? (
							<h4 className='text-center p-2'>
								<FaCogs></FaCogs> Filtro avanzado ({filterOptions.formType})
							</h4>
						) : (
							''
						)}
						<Form.Group as={Row} className='mb-3 align-items-center'>
							<Form.Label column md={3} size='sm'>
								Moneda:
							</Form.Label>
							<Col md={9}>
								<GenericDropdown
									label='Moneda'
									items={currencies}
									selected={filterOptions.currency}
									onChange={handleInputChange}
									name='currency'
									isInFilterForm={true}
								/>
							</Col>
						</Form.Group>
						<Form.Group as={Row} className='mb-3 align-items-center'>
							<Form.Label column md={2} size='sm'>
								Fecha:
							</Form.Label>
							<Col md={5}>
								<Form.Control
									type='date'
									name='periodInitFrom'
									size='sm'
									value={filterOptions.periodInitFrom}
									onChange={handleInputChange}
								/>
							</Col>
							<Col md={5}>
								<Form.Control
									type='date'
									name='periodInitTo'
									size='sm'
									value={filterOptions.periodInitTo}
									onChange={handleInputChange}
								/>
							</Col>
						</Form.Group>
						<Form.Group as={Row} className='mb-3 align-items-center'>
							<Form.Label column md={2} size='sm'>
								Vto:
							</Form.Label>
							<Col md={5}>
								<Form.Control
									type='date'
									name='periodEndFrom'
									size='sm'
									value={filterOptions.periodEndFrom}
									onChange={handleInputChange}
								/>
							</Col>
							<Col md={5}>
								<Form.Control
									type='date'
									name='periodInitTo'
									size='sm'
									value={filterOptions.periodInitTo}
									onChange={handleInputChange}
								/>
							</Col>
						</Form.Group>
						<Form.Group as={Row} className='mb-3 align-items-center'>
							<Form.Label column md={2} size='sm'>
								Monto:
							</Form.Label>
							<Col md={5}>
								<Form.Control
									type='number'
									name='amountFrom'
									size='sm'
									value={filterOptions.amountFrom}
									onChange={handleInputChange}
									placeholder='Desde'
								/>
							</Col>
							<Col md={5}>
								<Form.Control
									type='number'
									name='amountTo'
									size='sm'
									value={filterOptions.amountTo}
									onChange={handleInputChange}
									placeholder='Hasta'
								/>
							</Col>
						</Form.Group>
					</>
				)}
				{filterOptions.formType === 'ActivosFijos' && (
					<>
						{!pathname.includes('/reports') &&
						<h4 className='text-center p-2'>
							<FaCogs></FaCogs> Filtro avanzado ({filterOptions.formType})
						</h4>}
						{pathname.includes('/reports') ? (
							<>
								<Form.Group as={Row} className='mb-3 align-items-center'>
									<Form.Label column md={3} size='sm'>
									Nombre de activo:
									</Form.Label>
									<Col md={9}>
										<Form.Control
											type='text'
											name='name'
											size='sm'
											value={filterOptions.name}
											onChange={handleInputChange}
											placeholder='Nombre de activo'
										/>
									</Col>
								</Form.Group>
								<Form.Group as={Row} className='mb-3 align-items-center'>
									<Form.Label column md={3} size='sm'>
										Propietario:
									</Form.Label>
									<Col md={9}>
										<GenericDropdown
											label='owner'
											items={accountsList ? accountsList : ''}
											selected={filterOptions.owner}
											onChange={handleInputChange}
											name='owner'
											isInFilterForm={true}
											accountsList={true}
										/>
									</Col>
								</Form.Group>
								<Form.Group as={Row} className='mb-3 align-items-center'>
									<Form.Label column md={3}>
								Vendido?:
									</Form.Label>
									<Col md={9}>
										<GenericDropdown
											label='Vendido'
											items={paidOptions}
											selected={filterOptions.sold}
											onChange={handleInputChange}
											name='sold'
											isInFilterForm={true}
										/>
									</Col>
								</Form.Group>
								<Form.Group as={Row} className='mb-3 align-items-center'>
									<Form.Label column md={3} size='sm'>
									Padron:
									</Form.Label>
									<Col md={9}>
										<Form.Control
											type='text'
											name='lot'
											size='sm'
											value={filterOptions.lot}
											onChange={handleInputChange}
											placeholder='Padron'
										/>
									</Col>
								</Form.Group>
								<Form.Group as={Row} className='mb-3 align-items-center'>
									<Form.Label column md={3} size='sm'>
									Ubicación:
									</Form.Label>
									<Col md={9}>
										<Form.Control
											type='text'
											name='ubication'
											size='sm'
											value={filterOptions.ubication}
											onChange={handleInputChange}
											placeholder='Ubicacion'
										/>
									</Col>
								</Form.Group>
							</>
						) : null}					
						<Form.Group as={Row} className='mb-3 align-items-center'>
							<Form.Label column md={3} size='sm'>
								Moneda:
							</Form.Label>
							<Col md={9}>
								<GenericDropdown
									label='Moneda'
									items={currencies}
									selected={filterOptions.currency}
									onChange={handleInputChange}
									name='currency'
									isInFilterForm={true}
								/>
							</Col>
						</Form.Group>
						<Form.Group as={Row} className='mb-3 align-items-center'>
							<Form.Label column md={2} size='sm'>
								Monto:
							</Form.Label>
							<Col md={5}>
								<Form.Control
									type='number'
									name='priceFrom'
									size='sm'
									value={filterOptions.priceFrom}
									onChange={handleInputChange}
									placeholder='Desde'
								/>
							</Col>
							<Col md={5}>
								<Form.Control
									type='number'
									name='priceTo'
									size='sm'
									value={filterOptions.priceTo}
									onChange={handleInputChange}
									placeholder='Hasta'
								/>
							</Col>
						</Form.Group>
					</>
				)}
				{filterOptions.formType === 'VentasActivo' && (
					<>
						{!pathname.includes('/reports') && !pathname.includes('/predictions') ? (
							<h4 className='text-center p-2'>
								<FaCogs></FaCogs> Filtro avanzado ({filterOptions.formType})
							</h4>
						) : (
							''
						)}
						<Form.Group as={Row} className='mb-3 align-items-center'>
							<Form.Label column md={3} size='sm'>
								Comprador:
							</Form.Label>
							<Col md={9}>
								<GenericDropdown
									label='purchaser'
									items={accountsList ? accountsList : ''}
									selected={filterOptions.purchaser}
									onChange={handleInputChange}
									name='purchaser'
									isInFilterForm={true}
									accountsList={true}
								/>
							</Col>
						</Form.Group>
						<Form.Group as={Row} className='mb-3 align-items-center'>
							<Form.Label column md={3} size='sm'>
								Moneda:
							</Form.Label>
							<Col md={9}>
								<GenericDropdown
									label='Moneda'
									items={currencies}
									selected={filterOptions.currency}
									onChange={handleInputChange}
									name='currency'
									isInFilterForm={true}
								/>
							</Col>
						</Form.Group>
						<Form.Group as={Row} className='mb-3 align-items-center'>
							<Form.Label column md={3} size='sm'>
									Padron:
							</Form.Label>
							<Col md={9}>
								<Form.Control
									type='text'
									name='lot'
									size='sm'
									value={filterOptions.lot}
									onChange={handleInputChange}
									placeholder='Padron'
								/>
							</Col>
						</Form.Group>
						<Form.Group as={Row} className='mb-3 align-items-center'>
							<Form.Label column md={2} size='sm'>
								Fecha:
							</Form.Label>
							<Col md={5}>
								<Form.Control
									type='date'
									name='dateFrom'
									size='sm'
									value={filterOptions.dateFrom}
									onChange={handleInputChange}
								/>
							</Col>
							<Col md={5}>
								<Form.Control
									type='date'
									name='dateTo'
									size='sm'
									value={filterOptions.dateTo}
									onChange={handleInputChange}
								/>
							</Col>
						</Form.Group>
					</>
				)}
				{filterOptions.formType === 'Cuentas' && (
					<>
						<h4 className='text-center p-2'>
							<FaCogs></FaCogs> Filtro avanzado ({filterOptions.formType})
						</h4>
						<Form.Group as={Row} className='mb-3 align-items-center'>
							<Form.Label column md={3} size='sm'>
								Deudor o Acreedor:
							</Form.Label>
							<Col md={9}>
								<GenericDropdown
									label='TipoCuenta'
									items={accountTypes}
									selected={filterOptions.accountType}
									onChange={handleInputChange}
									name='accountType'
									isInFilterForm={true}
								/>
							</Col>
						</Form.Group>
						<Form.Group as={Row} className='mb-3 align-items-center'>
							<Form.Label column md={3} size='sm'>
								Tipo:
							</Form.Label>
							<Col md={9}>
								<GenericDropdown
									label='Tipo'
									items={types}
									selected={filterOptions.type}
									onChange={handleInputChange}
									name='type'
									isInFilterForm={true}
								/>
							</Col>
						</Form.Group>
						<Form.Group as={Row} className='mb-3 align-items-center'>
							<Form.Label column md={3} size='sm'>
								Moneda:
							</Form.Label>
							<Col md={9}>
								<GenericDropdown
									label='Moneda'
									items={currencies}
									selected={filterOptions.currency}
									onChange={handleInputChange}
									name='currency'
									isInFilterForm={true}
								/>
							</Col>
						</Form.Group>
					</>
				)}
				{filterOptions.formType === 'CuotasDeudas' && !pathname.includes('/predictions') && (
					<>
						{!pathname.includes('/reports') && !pathname.includes('/predictions') ? (
							<h4 className='text-center p-2'>
								<FaCogs></FaCogs> Filtro avanzado ({filterOptions.formType})
							</h4>
						) : (
							''
						)}
						<Form.Group as={Row} className='mb-3 align-items-center'>
							<Form.Label column md={3}>
								Concepto:
							</Form.Label>
							<Col md={9}>
								<GenericDropdown
									label='Concepto'
									items={debtConcepts}
									selected={filterOptions.concept}
									onChange={handleInputChange}
									name='concept'
									isInFilterForm={true}
								/>
							</Col>
						</Form.Group>
						<Form.Group as={Row} className='mb-3 align-items-center'>
							<Form.Label column md={3}>
								Pago:
							</Form.Label>
							<Col md={9}>
								<GenericDropdown
									label='Pago'
									items={paidOptions}
									selected={filterOptions.paid}
									onChange={handleInputChange}
									name='paid'
									isInFilterForm={true}
								/>
							</Col>
						</Form.Group>
						<Form.Group as={Row} className='mb-3 align-items-center'>
							<Form.Label column md={3} size='sm'>
								Moneda:
							</Form.Label>
							<Col md={9}>
								<GenericDropdown
									label='Moneda'
									items={currencies}
									selected={filterOptions.currency}
									onChange={handleInputChange}
									name='currency'
									isInFilterForm={true}
								/>
							</Col>
						</Form.Group>
						<Form.Group as={Row} className='mb-3 align-items-center'>
							<Form.Label column md={2} size='sm'>
								Fecha vencimiento:
							</Form.Label>
							<Col md={5}>
								<Form.Control
									type='date'
									name='dateFrom'
									size='sm'
									value={filterOptions.dateFrom}
									onChange={handleInputChange}
								/>
							</Col>
							<Col md={5}>
								<Form.Control
									type='date'
									name='dateTo'
									size='sm'
									value={filterOptions.dateTo}
									onChange={handleInputChange}
								/>
							</Col>
						</Form.Group>
					</>
				)}
				{filterOptions.formType === 'CuotasDeudas' && pathname.includes('/predictions') && (
					<>
						{!pathname.includes('/reports') && !pathname.includes('/predictions') ? (
							<h4 className='text-center p-2'>
								<FaCogs></FaCogs> Filtro avanzado ({filterOptions.formType})
							</h4>
						) : (
							''
						)}
						<Form.Group as={Row} className='mb-3 align-items-center'>
							<Form.Label column md={2} size='sm'>
								Fecha vencimiento:
							</Form.Label>
							<Col md={5}>
								<Form.Control
									type='date'
									name='dateFrom'
									size='sm'
									value={filterOptions.dateFrom}
									onChange={handleInputChange}
									min={moment.utc().startOf('month').format('YYYY-MM-DD')}
								/>
							</Col>
							<Col md={5}>
								<Form.Control
									type='date'
									name='dateTo'
									size='sm'
									value={filterOptions.dateTo}
									onChange={handleInputChange}
									min={moment.utc().startOf('month').format('YYYY-MM-DD')}
								/>
							</Col>
						</Form.Group>
					</>
				)}
				{filterOptions.formType === 'CuotasCreditos' && pathname.includes('/predictions') && (
					<>
						{!pathname.includes('/reports') && !pathname.includes('/predictions') ? (
							<h4 className='text-center p-2'>
								<FaCogs></FaCogs> Filtro avanzado ({filterOptions.formType})
							</h4>
						) : (
							''
						)}
						<Form.Group as={Row} className='mb-3 align-items-center'>
							<Form.Label column md={2} size='sm'>
								Fecha vencimiento:
							</Form.Label>
							<Col md={5}>
								<Form.Control
									type='date'
									name='dateFrom'
									size='sm'
									value={filterOptions.dateFrom}
									onChange={handleInputChange}
									min={moment.utc().startOf('month').format('YYYY-MM-DD')}
								/>
							</Col>
							<Col md={5}>
								<Form.Control
									type='date'
									name='dateTo'
									size='sm'
									value={filterOptions.dateTo}
									onChange={handleInputChange}
									min={moment.utc().startOf('month').format('YYYY-MM-DD')}
								/>
							</Col>
						</Form.Group>
					</>
				)}
				{filterOptions.formType === 'CuotasCreditos' && !pathname.includes('/predictions') && (
					<>
						{!pathname.includes('/reports') && !pathname.includes('/predictions') ? (
							<h4 className='text-center p-2'>
								<FaCogs></FaCogs> Filtro avanzado ({filterOptions.formType})
							</h4>
						) : (
							''
						)}
						<Form.Group as={Row} className='mb-3 align-items-center'>
							<Form.Label column md={3}>
								Concepto:
							</Form.Label>
							<Col md={9}>
								<GenericDropdown
									label='Concepto'
									items={creditConcepts}
									selected={filterOptions.concept}
									onChange={handleInputChange}
									name='concept'
									isInFilterForm={true}
								/>
							</Col>
						</Form.Group>
						<Form.Group as={Row} className='mb-3 align-items-center'>
							<Form.Label column md={3}>
								Pago:
							</Form.Label>
							<Col md={9}>
								<GenericDropdown
									label='Pago'
									items={paidOptions}
									selected={filterOptions.paid}
									onChange={handleInputChange}
									name='paid'
									isInFilterForm={true}
								/>
							</Col>
						</Form.Group>
						<Form.Group as={Row} className='mb-3 align-items-center'>
							<Form.Label column md={3} size='sm'>
								Moneda:
							</Form.Label>
							<Col md={9}>
								<GenericDropdown
									label='Moneda'
									items={currencies}
									selected={filterOptions.currency}
									onChange={handleInputChange}
									name='currency'
									isInFilterForm={true}
								/>
							</Col>
						</Form.Group>
						<Form.Group as={Row} className='mb-3 align-items-center'>
							<Form.Label column md={2} size='sm'>
								Fecha vencimiento:
							</Form.Label>
							<Col md={5}>
								<Form.Control
									type='date'
									name='dateFrom'
									size='sm'
									value={filterOptions.dateFrom}
									onChange={handleInputChange}
								/>
							</Col>
							<Col md={5}>
								<Form.Control
									type='date'
									name='dateTo'
									size='sm'
									value={filterOptions.dateTo}
									onChange={handleInputChange}
								/>
							</Col>
						</Form.Group>
					</>
				)}
				{filterOptions.formType === 'EstadoCuenta' && (
					<>
						<Form.Group as={Row} className='mb-3 align-items-center'>
							<Form.Label column md={3} size='sm'>
								Cuenta:
							</Form.Label>
							<Col md={9}>
								<GenericDropdown
									label='Cuenta'
									items={accountsList ? accountsList : ''}
									selected={filterOptions.accountId}
									onChange={handleInputChange}
									name='accountId'
									isInFilterForm={true}
									accountsList={true}
								/>
							</Col>
						</Form.Group>
						<Form.Group as={Row} className='mb-3 align-items-center'>
							<Form.Label column md={2} size='sm'>
								Fecha vencimiento:
							</Form.Label>
							<Col md={5}>
								<Form.Control
									type='date'
									name='dateFrom'
									size='sm'
									value={filterOptions.dateFrom}
									onChange={handleInputChange}
								/>
							</Col>
							<Col md={5}>
								<Form.Control
									type='date'
									name='dateTo'
									size='sm'
									value={filterOptions.dateTo}
									onChange={handleInputChange}
								/>
							</Col>
						</Form.Group>
					</>
				)}
				{!pathname.includes('/reports') && !pathname.includes('/predictions') ? (
					<Row className='justify-content-center mt-2 mb-2'>
						<Col className='d-flex justify-content-end'>
							<Button variant='secondary' size='sm' className='mr-2' onClick={clearFilter}>
								Limpiar Filtro
							</Button>
						</Col>
						<Col className='d-flex justify-content-end'>
							<Button variant='primary' size='sm' type='submit'>
								Aplicar Filtro
							</Button>
						</Col>
					</Row>
				) : (
					<Row className='d-flex justify-content-end align-items-center mt-3'>
						<Col className='d-flex justify-content-end col-10'>
							<Button variant='primary' size='lg' type='submit'>
								Generar Reporte
							</Button>
						</Col>
						<Col className='d-flex justify-content-start col-2'>
							<Button variant='secondary' size='sm' className='mr-2' onClick={clearFilter}>
								Limpiar Filtro
							</Button>
						</Col>
					</Row>
				)}
			</Form>
		</div>
	);
};

export default FilterForm;
