import axios from 'axios';
import { customHistory } from './CustomBrowserRouter';
import axiosInstance from './RestClient.js';

export const getError = (error) => {
	return error.response && error.response.data.message
		? error.response.data.message
		: error.message;
};
export const getStatus = (error) => {
	return error.response && error.response.status
		? error.response.status
		: error.message;
};
export const showCurrency = (currency, capital, list) => {
	if (list === 'debts') {
		if (!capital || capital === '' || capital === null) {
			return '';
		} else if (currency === 'UYU') {
			return '$U';
		} else if (currency === 'USD') {
			return 'U$S';
		}
	} else {
		if (currency === 'UYU') {
			return '$U';
		} else if (currency === 'USD') {
			return 'U$S';
		}
	}
};
//lists for dropdowns
export const types = ['Persona', 'Empresa'];
export const concepts = ['prestamos-amortizable', 'prestamos-interés', 'alquileres', 'ventas-a-plazo'];
export const periodicities = ['mensual', 'semestral', 'anual', 'otra'];
export const currencies = ['USD', 'UYU'];
export const monthOptions = ['Mes actual', 'Mes siguiente', 'En tres meses', 'En seis meses', 'Otro'];
export const dueDays = Array.from({ length: 31 }, (_, index) => (index + 1).toString());
/**
 * @param {String} hex Six digit hexadecimal color
 * @param {Number} alpha Number between 0 and 1
 */
export const addAlphaToHex = (hex, alpha) => {
	let convertedColor = hex;
	if (hex.length === 6 && alpha > 0 && alpha <= 1) {
		const hexValues = hex.match(/.{1,2}/g);
		const rgbArray = [
			parseInt(hexValues[0], 16),
			parseInt(hexValues[1], 16),
			parseInt(hexValues[2], 16),
		];
		convertedColor = `rgb(${rgbArray[0]},${rgbArray[1]},${rgbArray[2]},${alpha})`;
	}
	return convertedColor;
};

export const regexEmail =
	/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

export async function refreshToken() {
	const refresTkn = localStorage.getItem('refreshToken');
	const response = await axiosInstance.post('/auth/refreshToken', { refresTkn });
	const { newRefreshToken, tokenn } = response.data;
	const userInfo = JSON.parse(localStorage.getItem('userInfo'));
	userInfo.token = tokenn;
	localStorage.removeItem('userInfo');
	localStorage.setItem('userInfo', JSON.stringify(userInfo));
	if (newRefreshToken) {
		localStorage.removeItem('refreshToken');
		localStorage.setItem('refreshToken', newRefreshToken);
	}
	return tokenn;
}

export const signoutHandler = async () => {
	const refreshToken = localStorage.getItem('refreshToken');
	const userInfo = JSON.parse(localStorage.getItem('userInfo'));
	if (userInfo && refreshToken) {
		try {
			await axios.post('/api/auth/logout', {
				refreshToken,
				userId: userInfo._id,
			});
		} catch (error) {
			console.error('SIGNOUT HANDLER ERROR: ', error);
		}
	}
	if (userInfo) {
		localStorage.removeItem('userInfo');
	}
	if (refreshToken) {
		localStorage.removeItem('refreshToken');
	}
	customHistory.push('/signin');
};

export const logoutHandler = async () => {
	// const { state, dispatch: ctxDispatch } = useContext(Store);

	const refreshToken = localStorage.getItem('refreshToken');
	const userInfo = JSON.parse(localStorage.getItem('userInfo'));
	if (userInfo && refreshToken) {
		await axios.post('/api/auth/logout', {
			refreshToken,
			userId: userInfo._id,
		});
	}
	// localStorage.removeItem("userInfo");
	// localStorage.removeItem("refreshToken");
	localStorage.clear();
	customHistory.push('/signin');
};

export function getToken() {
	const userInfo = JSON.parse(localStorage.getItem('userInfo'));
	const token = userInfo ? userInfo.token : null;
	return token;
}

export function getUserInfo() {
	const userInfo = JSON.parse(localStorage.getItem('userInfo'));
	return userInfo;
}

/* for show data in tables: types string, currency(gotaccount case), boolean(show 'Si', o 'No', and date, also set link option to true,
and a pathLink , to sho the data as a link, the toExport option, is to show the data to export, but not in the screen) */

export	const accountColumns = [
	{ name: {type: 'string', label: 'Nombre' , link:true}},
	{ type: {type: 'string', label: 'Tipo'} },
	{ currency: { type: 'string', label: 'Moneda' }},
	{ folder: { type: 'string', label: 'Carpeta' }}
];

export const contactsColumns = [
	{ 'account.name':{type: 'string', label: 'Cuenta', link:true}},
	{ name:{type:'string', label: 'Nombre' }},
	{ email:{type: 'string', label: 'Email' }},
	{ address:{ type: 'string', label: 'Dirección' }},
	{ phone:{ type: 'string', label: 'Teléfono'}},
	{ folder:{type: 'string', label: 'Carpeta'}}
];

export const contractsColumns = [
	{ customId: { type: 'string', label: 'Inquilino:Cuenta', link: true } },
	{ currency: { type: 'string', label: 'Moneda', toExport: true } },
	{ amount: { type: 'currency', label: 'Monto' } },
	{ periodInit: { type: 'date', label: 'Inicio' } },
	{ periodEnd: { type: 'date', label: 'Fin' } },
	{ 'owner.name': { type: 'string', label: 'Inquilino', link:true } },
	{ folder: { type: 'string', label: 'Carpeta' } },
	{ closeDate: { type: 'date', label: 'Fecha Finalización'} },
	{ previousDebt: { type: 'string', label: 'Saldo Anterior', toExport: true } },
	{ previousDebtDate: { type: 'date', label: 'Fecha de saldo Anterior', toExport: true } },
	{ field: { type: 'string', label: 'detalles', toExport: true } },
	{ paymentMethod: { type: 'string', label: 'Método de pago', toExport: true } },
	{ observations: { type: 'string', label: 'Observaciones', toExport: true } }
];

export const debtsColumns = [
	{ customId:{type: 'string', label: 'Cuenta:Concepto',link:true}},
	{ 'owner.name':{ type: 'string', label: 'Deudor' ,link:true}},
	{ capital: {type:'currency', label: 'Capital' }},
	{ concept:{type: 'string', label: 'Concepto' }},
	{ dues: {type: 'number', label: 'Cuotas' }},
	{ amount: {type: 'currency', label: 'Monto cuota' }},
	{ date: {type: 'date', label: 'Fecha' }},
	{ endDate:{ type: 'date', label: 'Fecha vto' }},
	{ periodicity:{type: 'string', label: 'Periodicidad'}},
	{ currency:{type: 'string', label: 'Moneda', toExport: true }},
	{ previousDebt:{type: 'string', label: 'Deuda Anterior', toExport: true }},
	{ previousDebtDate:{type: 'date', label: 'Fecha de Deuda Anterior', toExport: true }},
	{ interest:{type: 'string', label: '% Interés', toExport: true }}
];

export const creditsColumns = [
	{ customId: {type: 'string', label: 'Cuenta:Concepto', link: true} },
	{ 'owner.name': {type: 'string', label: 'Acreedor', link: true} },
	{ capital: {type: 'currency', label: 'Capital'} },
	{ concept: {type: 'string', label: 'Concepto'} },
	{ dues: {type: 'number', label: 'Cuotas'} },
	{ amount: {type: 'currency', label: 'Monto cuota'} },
	{ date: {type: 'date', label: 'Fecha'} },
	{ endDate: {type: 'date', label: 'Fecha vto'} },
	{ periodicity: {type: 'string', label: 'Periodicidad'} },
	{ guaranteedAmount: {type: 'currency', label: 'Total Garantizado'} },
	{ currency: {type: 'string', label: 'Moneda', toExport: true} },
];

export const assetsColumns = [
	{ customId:{type: 'string', label: 'Activo Fijo',link:true }},
	{ currency:{type: 'string', label: 'Moneda', toExport: true }},
	{ price:{type: 'currency', label: 'Precio/Valor' }},
	{ type:{type: 'string', label: 'Tipo' }},
	{ ubication:{type: 'string', label: 'Ubicación' }},
	{ description:{type: 'string', label: 'Descripción'}},
	{ 'owner.name':{type: 'string', label: 'Propietario',link:true}},
	{ folder:{type: 'string', label: 'Carpeta'}},
	{ observations:{type: 'string', label: 'Observaciones', toExport: true}}
];
export const paymentsColumns = [
	{ customId:{type: 'string', label: 'ID', link:true }},
	{ 'debtDue.customId':{type: 'string', label: 'Cuota'}},
	{ amount:{type: 'currency', label: 'Monto'}},
	{ date:{type: 'date', label: 'Fecha'}},
	{ folder:{type: 'string', label: 'Carpeta'}},
	{ observations:{type: 'string', label: 'Observaciones', toExport: true }},
	{ status:{type: 'string', label: 'Estado'}},
];
export const creditPaymentsColumns = [
	{ customId: { type: 'string', label: 'ID' } },
	{ 'creditDue.customId': { type: 'string', label: 'Cuota' } },
	{ amount: { type: 'currency', label: 'Monto' } },
	{ date: { type: 'date', label: 'Fecha' } },
	{ folder: { type: 'string', label: 'Carpeta' } },
	{ 'credit.currency': { type: 'string', label: 'Moneda', toExport: true } },
	{ observations: { type: 'string', label: 'Observaciones', toExport: true } }
];

export const relWarrantiesColumns = [
	{ 'warranty.option': { type: 'string', label: 'Opción' } },
	{ 'warranty.debt.customId': { type: 'string', label: 'Deuda' } },
	{ 'warranty.debt.currency': { type: 'string', label: 'Moneda Deuda', toExport: true } },
	{ 'warranty.asset.customId': { type: 'string', label: 'Activo Fijo' } },
	{ 'warranty.asset.currency': { type: 'string', label: 'Moneda Activo', toExport: true } },
	{ 'warranty.amount': { type: 'currency', label: 'Monto' } }
];

export const debtDuesColumns = [
	{ customId: { type: 'string', label: 'Cuota' } },
	{ date: { type: 'date', label: 'Fecha' } },
	{ interest: { type: 'currency', label: 'Interes' } },
	{ 'debt.currency': { type: 'currency', label: 'Moneda' } },
	{ amortization: { type: 'currency', label: 'Amortización' } },
	{ amount: { type: 'currency', label: 'Monto' } },
	{ expiration: { type: 'date', label: 'Vto' } },
	{ balance: { type: 'currency', label: 'Saldo' } },
	{ unpaidAmount: { type: 'currency', label: 'Monto pendiente' } },
	{ paid: { type: 'boolean', label: 'Pago?' } },
	{ 'debt.customId': { type: 'string', label: 'Deudor', toExport: true } },
	{ 'debt.concept': { type: 'string', label: 'Concepto', toExport: true } },
	{ status:{type: 'string', label: 'Estado'}},

];

export const creditDuesColumns = [
	{ customId: { type: 'string', label: 'Cuota' } },
	{ date: { type: 'date', label: 'Fecha' } },
	{ interest: { type: 'currency', label: 'Interes' } },
	{ 'credit.currency': { type: 'currency', label: 'Moneda' } },
	{ amortization: { type: 'currency', label: 'Amortización' } },
	{ amount: { type: 'currency', label: 'Monto' } },
	{ expiration: { type: 'date', label: 'Vto' } }
];
export const billsColumns = [
	{ customId: { type: 'string', label: 'customId', link:true} },
	{ name: { type: 'string', label: 'Nombre' } },
	{ 'asset.currency': { type: 'string', label: 'Moneda', toExport: true } },
	{ amount: { type: 'currency', label: 'Monto' } },
	{ expirationDate: { type: 'date', label: 'Vencimiento' } },
	{ type: { type: 'string', label: 'Tipo' } },
	{ periodicity: { type: 'string', label: 'Periodicidad' } },
	{ paid: { type: 'boolean', label: 'Pago?' } },
	{ observations: { type: 'string', label: 'Observaciones', toExport: true } }
];

export const assetSalesColumns = [
	{ customId: { type: 'string', label: 'Id', link:true} },
	{ date: { type: 'date', label: 'Fecha'} },
	{ lot: { type: 'string', label: 'Padrón' } },
	{ amount: { type: 'currency', label: 'Monto' } },
	{ 'purchaser.name': { type: 'string', label: 'Comprador'} },
	{ sumDebts: { type: 'currency', label: 'Total Deudas' } }
];